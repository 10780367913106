<template>
    <div class="mb-16">
        <v-row justify="start" align="center" class="mx-0 my-4">
            <h2 class="dashboard">Посещения клиентов, после которых они не вернулись на услугу</h2>
        </v-row>
        <DidntReturnVisitsSubHeader :subHeaderData="subHeaderData"></DidntReturnVisitsSubHeader>
        <v-data-table :headers="headers" :items="chartData" @current-items="setSubHeaderData">
            <template v-slot:[`item.client_name_with_num`]="{ item }">
                <div>
                    <span class="communication-user">{{ item.client_name_with_num }}</span>
                </div>
                <div>
                    <PhoneLink :hrefInfo="item.href_info"></PhoneLink>
                </div>
            </template>
            <template v-slot:[`item.visit_date`]="{ item }">
                <span>{{ moment(item.visit_date).format(dateFormat) }}</span>
            </template>
            <template v-slot:[`item.action`]="{ item }">
                <div class="table-buttons-wrapper">
                    <AmoCrmLink :hrefInfo="item.href_info"></AmoCrmLink>
                    <YClientsLink :hrefInfo="item.href_info"></YClientsLink>
                    <ProfSalonLink :hrefInfo="item.href_info"></ProfSalonLink>
                    <SonlineLink :hrefInfo="item.href_info"></SonlineLink>
                    <AltegioLink :hrefInfo="item.href_info"></AltegioLink>
                    <DikidiLink :hrefInfo="item.href_info"></DikidiLink>
                    <ChatLink
                        :hrefInfo="item.href_info"
                        :chatLinkSettings="connector.whatsappLinkSettings"
                        :textMessage="getChatTextMessage(item)"
                    ></ChatLink>
                </div>
            </template>
        </v-data-table>
    </div>
</template>

<script>
/* Посещения клиентов, после которых они не вернулись на услугу */
import { openInNewTab } from '../../../../../utils'
import HorizonalBarChart from '@/components/charts/HorizontalBarChart.js'
import SmallHint from '@/components/SmallHint.vue'
import DidntReturnVisitsSubHeader from './subHeaders/DidntReturnVisitsSubHeader.vue'
import { CONNECTOR_TYPES } from '@root/src/vars/general'
import PhoneLink from '@root/src/components/iconLinks/PhoneLink.vue'
import messageVarsService from '@root/src/helpers/gcb2/messageVars'
import AmoCrmLink from '@root/src/components/iconLinks/AmoCrmLink.vue'
import YClientsLink from '@root/src/components/iconLinks/YClientsLink.vue'
import ProfSalonLink from '@root/src/components/iconLinks/ProfSalonLink.vue'
import SonlineLink from '@root/src/components/iconLinks/SonlineLink.vue'
import AltegioLink from '@root/src/components/iconLinks/AltegioLink.vue'
import DikidiLink from '@root/src/components/iconLinks/DikidiLink.vue'
import ChatLink from '@root/src/components/iconLinks/ChatLink.vue'

export default {
    name: 'DidntReturnVisits',
    components: {
        HorizonalBarChart,
        SmallHint,
        DidntReturnVisitsSubHeader,
        AmoCrmLink,
        YClientsLink,
        ProfSalonLink,
        SonlineLink,
        AltegioLink,
        DikidiLink,
        ChatLink,
        PhoneLink
    },
    props: {
        chartData: {
            type: Array,
            default: () => [],
        },
    },
    data: () => ({
        dateFormat: 'DD MMMM YYYY г.',
        headers: [
            {
                text: 'Клиент',
                align: 'start',
                value: 'client_name_with_num',
                width: '17%'
            },
            {
                text: 'Мастер',
                align: 'start',
                value: 'staff_name',
            },
            {
                text: 'Услуга',
                align: 'start',
                value: 'service_name',
            },
            {
                text: 'Последняя дата посещения',
                value: 'visit_date',
                width: '160px',
            },
            {
                text: 'Филиал',
                value: 'project_name',
            },
            {
                text: '',
                value: 'action',
                sortable: false
            },
        ],
        fullBarWidth: 200,
        subHeaderData: {},
    }),
    computed: {
        project() {
            return this.$store.getters.projectById(this.$router.currentRoute.params.id)
        },
        connector() {
            return this.project.connectors.find(el => el.connectorType === CONNECTOR_TYPES.GCB2)
        },
    },
    methods: {
        openInNewTab,
        setSubHeaderData(curItems) {
            if (curItems.length > 0) {
                this.subHeaderData = {
                    clientName: curItems[0].client_name_with_num,
                    serviceName: curItems[0].service_name,
                    date: curItems[0].visit_date,
                }
            }
        },
        getChatTextMessage(manualCommunication) {
            return messageVarsService.resolveVars(
                manualCommunication.textMessage,
                this.connector,
                manualCommunication
            )
        },
    },

    created() {},
}
</script>
<style lang="sass" scoped>
::v-deep .v-data-table__wrapper
    overflow: visible !important
.table-buttons-wrapper
    position: relative
    display: flex
    flex-direction: row
    justify-content: flex-end
.communication-user
    font-weight: bold
</style>
